import 'core-js/stable'
import Vue from 'vue'
import App from './App'
import router from './router'
import CoreuiVue from '@coreui/vue'
import { iconsSet as icons } from './assets/icons/icons.js'
import store from './store'


import 'primeicons/primeicons.css'

import * as utils from './views/utils/utilsFunctions'
Vue.prototype.$utils = utils;

import VueCookie from 'vue-cookie'

Vue.use(VueCookie)

import VModal from 'vue-js-modal'
Vue.use(VModal)

import VueNotification from "@kugatsu/vuenotification";
Vue.use(VueNotification);

import 'vue-search-select/dist/VueSearchSelect.css'


/* Tabla*/
import Tabl from 'ant-design-vue/lib/table';
import Icon from 'ant-design-vue/lib/icon';
import Dropdown from 'ant-design-vue/lib/dropdown';
import Menu from 'ant-design-vue/lib/menu';
import Badge from 'ant-design-vue/lib/badge';
import DownOutlined from '@ant-design/icons-vue';

import 'ant-design-vue/dist/antd.css';

import VueObserveVisibility from 'vue-observe-visibility'

Vue.use(VueObserveVisibility)

import VueTippy, { TippyComponent } from "vue-tippy";

Vue.use(VueTippy, {
  directive: "tippy", // => v-tippy
  flipDuration: 0,
  popperOptions: {
    modifiers: {
      preventOverflow: {
        enabled: true,
      }
    }
  }
});

import "tippy.js/themes/light.css";
import "tippy.js/themes/light-border.css";
import "tippy.js/themes/google.css";
import "tippy.js/themes/translucent.css";

import VueCustomTooltip from '@adamdehaven/vue-custom-tooltip'

Vue.use(VueCustomTooltip, {
  name: 'VueCustomTooltip',
  color: '#fff',
  background: '#000',
  borderRadius: 12,
  fontWeight: 400,
})
import { tippy } from "vue-tippy";
Vue.use(VueTippy);
Vue.component("tippy", TippyComponent);

//import { DocumentEditorPlugin, DocumentEditorContainerPlugin } from '@syncfusion/ej2-vue-documenteditor';

//Vue.use(DocumentEditorPlugin);
//Vue.use(DocumentEditorContainerPlugin);

import VueCurrencyFilter from 'vue-currency-filter'


import VueTailwind from 'vue-tailwind'
import {TDropdown,} from 'vue-tailwind/dist/components'

const settings = {
  't-dropdown': {
    component: TDropdown,
    props: {
      fixedClasses: {
        button: 'flex items-center text-white block px-4 py-2 transition duration-100 ease-in-out border border-transparent rounded shadow-sm focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed',
        wrapper: 'inline-flex flex-col',
        dropdownWrapper: 'relative z-10',
        dropdown: 'origin-top-left absolute left-0 w-56 rounded shadow mt-1',
        enterClass: 'opacity-0 scale-95',
        enterActiveClass: 'transition transform ease-out duration-100',
        enterToClass: 'opacity-100 scale-100',
        leaveClass: 'opacity-100 scale-100',
        leaveActiveClass: 'transition transform ease-in duration-75',
        leaveToClass: 'opacity-0 scale-95'
      },
      classes: {
        button: 'bg-blue-500 hover:bg-blue-600',
        dropdown: 'bg-white'
      },
      variants: {
        danger: {
          button: 'bg-red-500 hover:bg-red-600',
          dropdown: 'bg-red-50'
        }
      }
    }
  }
}

Vue.use(VueTailwind, settings)

Vue.use(VueCurrencyFilter, [
    {
    symbol : '$',
    thousandsSeparator: '.',
    fractionCount: 0,
    fractionSeparator: ',',
    symbolPosition: 'front',
    symbolSpacing: false,
    avoidEmptyDecimals: ''
    },
    {
    name: 'soloNumeros',
    symbol: '',
    thousandsSeparator: '.',
    fractionCount: 0,
    fractionSeparator: ',',
    symbolPosition: 'back',
    symbolSpacing: false,
    avoidEmptyDecimals: undefined,
    },
    {
    name: 'dosDecimales',
    symbol: '$',
    thousandsSeparator: '.',
    fractionCount: 2,
    fractionSeparator: ',',
    symbolPosition: 'front',
    symbolSpacing: false,
    avoidEmptyDecimals: undefined,
    },
    {
    name: 'currency1',
    symbol: '%',
    thousandsSeparator: '.',
    fractionCount: 0,
    fractionSeparator: ',',
    symbolPosition: 'back',
    symbolSpacing: false,
    avoidEmptyDecimals: undefined,
    },
    {
    name: 'currency2',
    symbol: '%',
    thousandsSeparator: '.',
    fractionCount: 2,
    fractionSeparator: ',',
    symbolPosition: 'back',
    symbolSpacing: false,
    avoidEmptyDecimals: undefined,
    },
    {
    name: 'currency3',
    symbol: '%',
    thousandsSeparator: '.',
    fractionCount: 1,
    fractionSeparator: ',',
    symbolPosition: 'back',
    symbolSpacing: false,
    avoidEmptyDecimals: undefined,
    }]
)

Vue.filter('prettyBytes', function (num) {
  // jacked from: https://github.com/sindresorhus/pretty-bytes
  if (typeof num !== 'number' || isNaN(num)) {
    throw new TypeError('Expected a number');
  }

  var exponent;
  var unit;
  var neg = num < 0;
  var units = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  if (neg) {
    num = -num;
  }

  if (num < 1) {
    return (neg ? '-' : '') + num + ' B';
  }

  exponent = Math.min(Math.floor(Math.log(num) / Math.log(1000)), units.length - 1);
  num = (num / Math.pow(1000, exponent)).toFixed(2) * 1;
  unit = units[exponent];

  return (neg ? '-' : '') + num + ' ' + unit;
});

Vue.component(Tabl.name, Tabl);
Vue.component(Icon.name, Icon);
Vue.component(Dropdown.name, Dropdown);
Vue.component(DownOutlined.name, DownOutlined);
Vue.component(Menu.name, Menu);
Vue.component(Badge.name, Badge);

/* fin tabla*/

import Vuesax from 'vuesax'
import 'vuesax/dist/vuesax.css' //Vuesax styles
Vue.use(Vuesax,  {
    colors: {
      primary:'#5b3cc4',
      success:'rgb(23, 201, 100)',
      danger:'rgb(242, 19, 93)',
      warning:'rgb(255, 130, 0)',
      dark:'rgb(36, 33, 69)'
    }
  })


/*
import VueDatepickerUi from 'vue-datepicker-ui'
import 'vue-datepicker-ui/lib/vuedatepickerui.css';

Vue.component('Datepicker', VueDatepickerUi)

import 'v2-datepicker/lib/index.css';   // v2 need to improt css
import V2Datepicker from 'v2-datepicker';

Vue.use(V2Datepicker)


import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify)

import DatetimePicker from 'vuetify-datetime-picker'
 
Vue.use(DatetimePicker)*/


/*import AirbnbStyleDatepicker from 'vue-airbnb-style-datepicker'
import 'vue-airbnb-style-datepicker/dist/vue-airbnb-style-datepicker.min.css'

Vue.use(AirbnbStyleDatepicker, {
  sundayFirst: false,
  texts: {
    apply: 'Aplicar',
    cancel: 'Cancelar',
    keyboardShortcuts: 'Keyboard Shortcuts',
  },
  days: ['Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado', 'Domingo'],
  daysShort: ['Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab','Dom'],
  monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio','Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
  keyboardShortcuts: [
    {symbol: '↵', label: 'Seleccionar fecha', symbolDescription: 'Enter key'},
    {symbol: '←/→', label: 'Mueve un día hacia atrás (izquierda) y adelante (derecha).', symbolDescription: 'Left or right arrow keys'},
    {symbol: '↑/↓', label: 'Mover una semana hacia atrás (arriba) y hacia adelante (abajo).', symbolDescription: 'Up or down arrow keys'},
    {symbol: 'PgUp/PgDn', label: 'Cambiar mes.', symbolDescription: 'PageUp and PageDown keys'},
    {symbol: 'Home/End', label: 'Desplazer al primer/último día de la semana.', symbolDescription: 'Home or End keys'},
    {symbol: 'Esc', label: 'Cerrar ayuda', symbolDescription: 'Escape key'},
    {symbol: '?', label: 'Abrir ayuda', symbolDescription: 'Question mark'}
  ]
})*/

/**
 * 
 * @param {int} tipo "1-success, 2-warning, 3-error, (Cualquier Otro)-info"
 * @param {string} mensaje "Mensaje de la notificacion"
 */
Vue.prototype.mostrarNotificaciones = function (tipo, mensaje) {
  var propiedadesNotificaciones = {timer: 10, position:"bottomRight"}
  if(tipo == 1){
    this.$notification.success(mensaje, propiedadesNotificaciones);
  }
  else if(tipo == 3){
    this.$notification.error(mensaje, propiedadesNotificaciones);
  }
  else if(tipo == 2){
    this.$notification.warning(mensaje, propiedadesNotificaciones);
  }
  else{
    this.$notification.info(mensaje, propiedadesNotificaciones);
  }             
}

Vue.config.performance = true
Vue.use(CoreuiVue)
Vue.prototype.$log = console.log.bind(console)

new Vue({
  el: '#app',
  router,
  store,
  icons,
  template: '<App/>',
  components: {
    App
  }
})
