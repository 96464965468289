import Vue from 'vue'
import Router from 'vue-router'

// Containers
const TheContainer = () => import('@/containers/TheContainer')

const AsignacionEmpresas = () => import('@/views/administracion/AsignacionEmpresas')
const AsignacionPerfiles = () => import('@/views/administracion/AsignacionPerfiles')
const UsuariosCrud = () => import('@/views/administracion/UsuariosCrud')
const AdministracionUsuarios = () => import('@/views/administracion/AdministracionUsuarios')
const AdministracionEmpresas = () => import('@/views/administracion/AdministracionEmpresas')


const Documentos = () => import('@/views/remuneraciones/RevisionDocumentos')
//const PortalCliente = () => import('@/views/administracion/PortalCliente')
const RevisionDocumentosPCliente = () => import('@/views/remuneraciones/RevisionDocumentosPCliente')
const ContratosFiniquitos = () => import('@/views/remuneraciones/ContratosFiniquitos')
const TemplateDocumentos = () => import('@/views/remuneraciones/TemplateDocumentos')

const EstadoResultados = () => import('@/views/contabilidad/EstadoResultado/EstadoResultados')
const EstadoResultadosComparativo = () => import('@/views/contabilidad/EstadoResultado/EstadoResultadoComparativo')
const Balance = () => import('@/views/contabilidad/Balance/Balance')
const BalanceComparativo = () => import('@/views/contabilidad/Balance/BalanceComparativo')
const Honorarios = () => import('@/views/contabilidad/Honorarios/DetalleHonorarios')

const CompraVenta = () => import('@/views/contabilidad/CompraVenta/CompraVenta')
const Contabilizacion = () => import('@/views/contabilidad/CompraVenta/Contabilizacion')

const Contratos = () => import('@/views/importaciones/remuneraciones/Contratos')

// Views - Pages
const Login = () => import('@/views/pages/Login')
const Login2 = () => import('@/views/pages/Logins/Login2')

const CambioPass =() => import('@/views/pages/CambioContrasenia')

const ReestablecerPsw = () => import('@/views/pages/ReestablecerPsw')


const TheContainerAdmin = () => import('@/vappAdminContainer/TheContainerAdmin')
const LoginAdmin = () => import('@/views/vappAdmin/LoginAdmin')
const ClientesAdmin = () => import('@/views/vappAdmin/ClientesAdmin')

const Indicadores = () => import('@/views/remuneraciones/IndicadoresPV')

const ModuloNoDisponible = () => import('@/views/pages/ModuloNoDisponible')

const PrincipalUsuario = () => import('@/views/pages/PrincipalUsuario')


const PerfiTrabajador = () => import('@/views/trabajador/PerfilTrabajador')

const Migracion = () => import('@/views/migraciones/Migracion')

/** PortalTrabajador */
const LoginTrabajador2 = () => import('@/views/pages/LoginTrabajador')
const PrincipalTrabajador = () => import('@/views/trabajador/PrincipalTrabajador')
const DocumentosTrabajador = () => import('@/views/trabajador/DocumentosTrabajador')

const LoginTrabajador = () => import('@/views/pages/Logins/LoginTrabajador2')

const VerIconos = () => import('@/views/administracion/VerIconos')


import cons from '@/const'
import { Comment } from 'ant-design-vue'
import axios from "axios";

Vue.use(Router)

export default new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})

function configRoutes () {
    /* REVISAR
    var url = cons.port+"://" + cons.ipServer + "get_utl_modulos"
    console.log("url", url)
    axios({
        method: "POST", 
        "url": url,
        "data": {
            
        },
        "headers": {
            'Authorization': `${JSON.parse(this.$cookie.get('userLogginToken')).tokenUsuario}`
        }
    }).then(result => {
        console.log("rute", result)
    })
    .finally(() => {});*/
  return [
    {
      path: '/',
      redirect: '/pages/login',
      name: '',
      component: TheContainer,
      children: [
        {
          path: 'perfil',
          name: 'Perfil',
          component: PerfiTrabajador
        },
        {
          path: 'VerIconos',
          name: 'VerIconos',
          component: VerIconos
        },
        {
          path: 'remuneraciones',
          //redirect: cons.rutaRedireccion, 
          name: 'Remuneraciones',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'templates_documentos',
              name: 'template Documentos',
              component: TemplateDocumentos
            },
            {
              path: 'contratos_finiquitos',
              name: 'Contratos/Finiquitos',
              component: ContratosFiniquitos
            },
            {
              path: 'documentos',
              name: 'Documentos',
              component: Documentos
            },
            /*{
              path: 'portal_cliente',
              name: 'Portal Clientes',
              component: PortalCliente
            },*/            
            {
              path: 'documentos_trabajador_revision',
              name: 'Documentos Revisión',
              component: RevisionDocumentosPCliente
            },
            {
              path: 'indicadores',
              name: 'Indicadores',
              component: Indicadores
            },
            {
              path: 'modulo_no_disponible',
              _name: 'CSidebarNavItem',
              name: 'ModuloNoDisponible',
              icon: 'cil-cilBan',
              component: ModuloNoDisponible
            },
            /* Protal Trabajador*/
            {
              path: 'documentos_trabajador',
              _name: 'CSidebarNavItem',
              name: 'Mis Documentos',
              component: DocumentosTrabajador
            }

          ]
        },
        {
          path: 'contabilidad',
          name: 'Contabilidad',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'estadoResultados',
              name: 'EstadoResultados',
              component: EstadoResultados
            },
            /*{
              path: 'estadoResultadosComparativo',
              name: 'Estado Resultados Comparativo',
              component: EstadoResultadosComparativo
            },*/
            {
              path: 'balance',
              name: 'balance',
              component: Balance
            },
            {
              path: 'balanceComparativo',
              name: 'Balance Comparativo',
              component: BalanceComparativo
            },
            {
              path: 'honorarios',
              name: 'Honorarios',
              component: Honorarios
            },
            {
              path: 'compra_venta',
              name: 'CompraVenta',
              component: CompraVenta
            },
            {
              path: 'contabilizacion',
              name: 'Contabilizacion',
              component: Contabilizacion
            },
            {
              path: 'modulo_no_disponible',
              _name: 'CSidebarNavItem',
              name: 'ModuloNoDisponible',
              icon: 'cil-cilBan',
              component: ModuloNoDisponible
            },
            
          ]
        },
        {
          path: 'migraciones',
          name: 'Migraciones',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'migracion',
              name: 'Migración',
              component: Migracion
            },
          ]
        },

        {
          path: 'administracion',
          redirect: '/administracion/administracion_usuarios',
          name: 'Administración',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'asignar_empresas',
              name: 'Asignar Empresas',
              component: AsignacionEmpresas
            },
            {
              path: 'asignar_perfiles',
              name: 'Asignar Perfiles',
              component: AsignacionPerfiles
            },
            {
              path: 'config_usuarios',
              name: 'Mis Usuarios',
              component: UsuariosCrud
            },
            {
              path: 'administracion_usuarios',
              name: 'Administrar Usuarios',
              component: AdministracionUsuarios
            },
            {
              path: 'administracion_empresas',
              name: 'Administrar Empresas',
              component: AdministracionEmpresas
            },
            {
              path: 'modulo_no_disponible',
              _name: 'CSidebarNavItem',
              name: 'ModuloNoDisponible',
              icon: 'cil-cilBan',
              component: ModuloNoDisponible
            }
          ]
        },
        {
          path: 'modulo_no_disponible',
          _name: 'CSidebarNavItem',
          name: 'ModuloNoDisponible',
          icon: 'cil-cilBan',
          component: ModuloNoDisponible
        },
        {
          path: 'principal_usuario',
          _name: 'CSidebarNavItem',
          name: 'Principal',
          icon: 'cil-cilBan',
          component: PrincipalUsuario
        },
        {
          path: 'principal_trabajador',
          _name: 'CSidebarNavItem',
          name: 'PrincipalTrabajador',
          icon: 'cil-cilBan',
          component: PrincipalTrabajador
        }
      ]
    },
    {
      path: '/pages',
      redirect: '/pages/404',
      name: 'Pages',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: 'login',
          name: 'Login',
          component: Login2
        },
        {
          path: 'login_trabajador',
          name: 'Login Trabajador',
          component: LoginTrabajador
        },
        {
          path: 'login_trabajador2',
          name: 'Login Trabajador2',
          component: LoginTrabajador2
        },
        {
          path: 'cambioPass',
          name: 'CambioPass',
          component: CambioPass
        },
        {
          path: 'reestablecerPsw',
          name: 'ReestablecerPsw',
          component: ReestablecerPsw
        },
        {
          path: 'loginOld',
          name: 'LoginOld',
          component: Login
        },
      ]
    },
    
    {
      path: '/vapp',
      name: 'Vapp',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: 'loginAdmin',
          name: 'LoginAdmin',
          component: LoginAdmin
        },
      ]
    },
    {
      path: '/admin',
      //redirect: '/vapp/loginAdmin',
      name: 'HomeAdmin',
      component: TheContainerAdmin,
      children: [
        {
          path: 'Administracion',
          //redirect: '/remuneraciones/documentos',
          name: 'Administracion',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'clientes',
              name: 'Clientes',
              component: ClientesAdmin
            }
          ]
        },
        /*{
          path: 'remuneraciones',
          //redirect: '/remuneraciones/documentos',
          name: 'Remuneraciones',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'documentos',
              name: 'Documentos',
              component: Documentos
            }
          ]
        },
        {
          path: 'administracion',
          redirect: '/administracion/asignar_empresas',
          name: 'Administración',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'asignar_empresas',
              name: 'Asignar Empresas',
              component: AsignacionEmpresas
            },
            {
              path: 'asignar_perfiles',
              name: 'Asignar Perfiles',
              component: AsignacionPerfiles
            },
            {
              path: 'config_usuarios',
              name: 'Mis Usuarios',
              component: UsuariosCrud
            }
          ]
        },*/
        
      ]
    },
  ]
}


/* PRUEBANDO

    async function getRoutes() {
    try {
        const response = await axios.get(`${cons.port}://${cons.ipServer}/get_utl_modulos`); // Cambia la URL por la de tu web service
        console.log("response", response)

        let routes = []
        response.data.modulos.forEach(modulo => {
            console.log("modulo", modulo)
            let mod = {
                path: modulo.path,
                name: '',
                component: () => import(`@/views/${modulo.component}`),
            }
            if(modulo.hasOwnProperty("redirect")){
                mod['redirect'] = modulo.redirect
            }
            if(modulo.hasOwnProperty("children")){
                let chil = []
                modulo.children.forEach(sm => {
                    let subMod = {
                        path: sm.path,
                        name: sm.name
                    }

                    if(typeof(sm.component) == 'object'){
                        mod['component'] = {
                            render (c) { return c('router-view') }
                        }
                    } else{
                        mod['component'] = () => import(`@/views/${sm.component}`)
                    }
                    if(sm.hasOwnProperty("children")){
                        let smChilds = []
                        sm.children.forEach(smChil => {
                            let objChild = {
                                path: smChil.path,
                                name: smChil.name,
                                component: () => import(`@/views/${smChil.component}`)
                            }
                            smChilds.push(objChild)
                        })
                    }

                    chil.push(subMod)
                })
                mod['children'] = chil
            }
            routes.push(mod)

        })
        //response.data.modulos.map(route => ({
        //path: route.path,
        //redirect: '/pages/login',

        //component: () => import(`@/views/${route.component}.vue`), // Carga de componentes de forma dinámica
        //}));
        console.log("routes", routes)
        return routes;
    } catch (error) {
        console.error('Error al obtener rutas:', error);
        return []; // En caso de error, retornar un array vacío
    }
    }

    // Crear el router de forma asíncrona
    const router = new Router({
    mode: 'history',
    routes: [] // Inicialmente vacío
    });

    // Configurar rutas de forma asíncrona
    getRoutes().then(routes => {
    routes.forEach(route => router.addRoute(route));
    });

    export default router;
*/